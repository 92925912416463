<template>
  <div>
    <Table
      title-key="code"
      :actions="actions"
      :items="$store.getters['order/tableData']"
      :fields="$store.getters['order/tableFields']"
      :loading="$store.state.order.loadingTable"
      :filter="$store.state.order.tableFilter"
      :tab-options="tabOptions"
      :sort="$store.state.order.tableSort"
      :sort-options="sortOptions"
      :keyword="$store.state.order.tableKeyword"
      :pagination="$store.state.order.tablePagination"
      search-placeholder="Search order number, service, buyer, seller"
      @changeFields="changeFields"
      @resetFields="resetFields"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
      @paginate="paginate"
      @search="search"
      @download="downloadData"
    />

    <!-- Cancel confirmation modal -->
    <b-modal
      v-model="cancelledModalVisible"
      title="Cancel Order"
      ok-title="Yes, cancel"
      cancel-variant="white"
      @ok="cancel"
    >
      Are you sure want to cancel this Order request?.
    </b-modal>
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Table,
  },
  data() {
    return {
      // Modal
      cancelledModalVisible: false,

      selectedData: '',
      // Sort
      sortOptions: [
        {
          key: 'createdAt', direction: 'desc', label: 'Newest', default: true,
        },
        { key: 'createdAt', direction: 'asc', label: 'Oldest' },
      ],

      // Tabs
      tabOptions: [
        // do not put value: undefined
        // because {..., value: undefined} is not equal {...}
        {
          key: 'statuses', label: 'All', default: true,
        },
        {
          key: 'statuses', label: 'Created', value: 'created',
        },
        {
          key: 'statuses', label: 'Verifying', value: 'verifying',
        },
        {
          key: 'statuses', label: 'Accepted', value: 'accepted',
        },
        { key: 'statuses', label: 'Start pending', value: 'start_pending' },
        { key: 'statuses', label: 'Start cancel', value: 'start_cancel' },
        { key: 'statuses', label: 'Started', value: 'started' },
        { key: 'statuses', label: 'Completed', value: 'completed' },
        { key: 'statuses', label: 'Cancelled', value: 'cancelled' },
      ],

      actions: [
        'view',
        'download',
        {
          type: 'other',
          children: [
            {
              label: 'Cancel',
              event: this.confirmCancel,
              visibility: {
                callback: () => this.canAccess('manage', 'Order'),
              },
            },
          ],
        },
      ],
    }
  },
  methods: {
    changeFields(val) {
      this.$store.commit('order/SET_TABLE_FIELDS', val)
    },
    resetFields() {
      this.$store.commit('order/RESET_TABLE_FIELDS')
    },
    loadData() {
      return this.$store.dispatch('order/getTableData')
    },
    filterData(val) {
      this.$store.commit('order/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('order/SET_TABLE_SORT', val)
    },
    confirmCancel(data) {
      this.selectedData = data.item
      this.cancelledModalVisible = true
    },
    cancel(data, callback) {
      this.$store.dispatch('order/changeStatus', {
        id: this.selectedData.id,
        data: {
          status: 'cancelled',
        },
      })
        .then(() => {
          this.cancelledModalVisible = false
          this.loadData()

          callback()
        })
    },
    paginate(val) {
      this.$store.commit('order/SET_TABLE_PAGINATION', val)
    },
    search(val) {
      this.$store.commit('order/SET_TABLE_KEYWORD', val)
    },
    downloadData() {
      return this.$store.dispatch('order/downloadTableData')
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Your data will be ready in a minutes!',
              variant: 'success',
            },
          })

          this.$router.push({ name: 'export-result' })
        })
    },
  },
}
</script>
